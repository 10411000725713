$(document).ready(function(){

  $('.m-menu, .menu-sc').click(function(){
    if ($('body').hasClass('m-menu-open')){
      $('body').removeClass('m-menu-open');
    }else{
      $('body').addClass('m-menu-open');
      if(! $('body').hasClass('scrolling')){
        // force mobile navigation
        $('body').addClass('scrolling');
        $("html, body").animate({ scrollTop: "200px" });
      }
    }
  });

  $('.up').click(function(){
    $("html, body").animate({ scrollTop: "0" });
  });

  $('.newscarousel').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '<button class="prev"></button>',
    nextArrow: '<button class="next"></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.themecarousel').slick({
    infinite: true,
    slidesToShow: 5,
    centerMode: true,
    // centerPadding: '60px',
    slidesToScroll: 1,
    focusOnSelect: true,
    prevArrow: '<button class="prev"></button>',
    nextArrow: '<button class="next"></button>',
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.pagecarousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    // adaptiveHeight: true,
    arrows: false,
  });

  $('.provcarousel').slick({
    infinite: true,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: '<button class="prev"></button>',
    nextArrow: '<button class="next"></button>',
    centerMode: true,
    variableWidth: true
  });


  // scroll stuff
  var scrolled = 0;
  function handleScroll () {
    scrolled = window.scrollY;
    var scrollOffset = 200;
    if(scrolled > scrollOffset){
      document.querySelector('body').classList.add('scrolling');
    }else{
      if(!$('body').hasClass('m-menu-open')){
        document.querySelector('body').classList.remove('scrolling');
      }
    }
  }

  window.addEventListener('scroll', handleScroll);


  // cookie consent
  function checkForCookies() {
    if (document.cookie.replace(/(?:(?:^|.*;\s*)likesCookies\s*\=\s*([^;]*).*$)|^.*$/, "$1") !== "true" && $('body').hasClass('pp') == false) {
      //console.log("No cookie consent");
      openCookieBanner();
    }
  }
  function openCookieBanner() {
    $('body').addClass('cbo');
    $(".acceptcookies").click(function(){
      acceptCookies();
    });
  }
  function acceptCookies(){
    //console.log('accept cookies');
    document.cookie = "likesCookies=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    $('body').removeClass('cbo');
  }
  function removeCookies(){
    document.cookie = "likesCookies=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  checkForCookies();

  // end cookies consent


  // contact
  $('#contact').submit(function(ev) {
    // Prevent the form from actually submitting
    ev.preventDefault();

    var data = $(this).serialize();
    data += '&csrfTokenName='+csrfTokenValue;
    // // Add the CSRF Token
    //data[csrfTokenName] = csrfTokenValue;

    // Send it to the server
    $.post({
        url: '/',
        method: 'POST',
        dataType: 'json',
        data: data,
        success: function(response) {
            if (response.success) {
              console.log('jojo' + response.success);
                $('.contact form').html('Bedankt voor jouw bericht! <br>We nemen spoedig contact op met jou.');
            } else {
                // response.error will be an object containing any validation errors that occurred, indexed by field name
                // e.g. response.error.fromName => ['From Name is required']
                console.log(response.error);
                $('.error').html('Er liep iets fout. Probeer het even opnieuw. ');
            }
        }
    });
  });

  $('.ask').click(function(e){
    e.preventDefault();
    $('body').addClass('contact-open');
  })

  $('.contact .close').click(function(){
    $('body').removeClass('contact-open');
  });
  // end contact


  // mailchimp
  $('.uptodate').click(function (e) {
    console.log('joo')
    e.stopPropagation();
    $('body').addClass('mco');
    $('body').removeClass('m-menu-open');

  });
  $('.close-btn').click(function (e) {
    $('body').removeClass('mco');
    e.stopPropagation();
  });

  $(document).click(function (e) {
    if ($('body').hasClass('mco')) {
      if (!$(event.target).closest('#mc_embed_signup,.mc-field-group').length) {
        $('body').removeClass('mco');
      }
    }
  });
  // end mailchimp

});


// faq page
  //*
    if(document.getElementById('questions')) {
    var app = new Vue({
      el: '#questions',

      data: {
        filters:['thema-1']
      },

      mounted: function(){
        var self = this;
        $('.themecarousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
          var catslug = $('.slick-slide.slick-current.slick-active').attr('data-catid');
          self.filter(catslug);
        });
      },


      methods: {

        filter: function(filter) {
          var self = this;
          self.filters.splice(0,1);
          self.filters.push(filter);
        },

        youaremytype: function(mytype){
          //return true;
          var self = this;
          var types = mytype.split(',');
          if(self.filters.length > 0){
            for (var i = types.length - 1; i >= 0; i--) {
              var io = self.filters.indexOf(types[i]);
              if(io != -1){
                return true;
              }
            }
          return false;
          }else{
            return true;
          }
        }
      }

    })
  }
  // */
  // end faq page